<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="org">
      <v-card-title class="headline">SSO configuration for {{org.name}}</v-card-title>

      <v-card-text>      
        <p>
          Enable and configure SSO for this organization.
        </p>

        <v-alert v-if="org.sso_config" type="success">
          <p>This organization has SSO enabled.</p>
        </v-alert>
      </v-card-text>

      <v-card-title>SSO Configuration</v-card-title>
      <v-card-text>
        Please contact support to help you configure this correctly.
      </v-card-text>
      <v-alert v-if="!orgUtil.isEnterprisePlan()" type="info" tile>
        <p><router-link class="white--text" :to="{name: 'orgmanagerSubscription', params: {id:org.id}}">Upgrade to the Enterprise plan</router-link> to enable SSO.</p>
      </v-alert>
      <v-card-text>
        <v-text-field
          v-model="config.firebase_provider"
          label="Firebase Provider ID"
          />
        <v-switch 
          v-model="config.required"
          label="Require SSO login"
          persistent-hint
          hint="When enabled, users are forced to authenticate through SSO when they want to join this event."
          />
        <v-combobox
          v-if="config.required"
          v-model="config.valid_domains"
          label="Valid domains (optional)"
          persistent-hint
          hint="Enter one or more valid domain names, each followed by the TAB key. When used, only users with an email address from one of the valid domains may join."
          class="mt-4"
          multiple
          chips
          />
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="!orgUtil.isEnterprisePlan()" color="secondary" @click="save">{{$t('shared.save')}}</v-btn>
      </v-card-actions>

    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import orgManagerService from "@/services/orgManagerService";
import OrgUtil from "@/util/orgUtil";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "IntegrationsSso",
  components: {
  },
  props: {
    org: Object,
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      event: null,
      config: {},
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async getEvent(id) {
      const org = (await orgManagerService.get(id)).data;
      this.config = org.sso_config || {};
    },

    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async save() {
      const response = (await orgManagerService.integrationsConfigureSso(this.org.id, this.config)).data;
      await this.loadData();
      this.$helpers.toastResponse(this, response, 'Successfully configured SSO.');
    },


  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Organizations', exact: true, to: { name: 'orgmanager' } },
        { text: this.org.name, exact: true, to: { name: 'orgmanagerView', params: {id: this.org.id}} },
        { text: 'Integrations', disabled: true },
        { text: 'SSO', disabled: true },
      ];
    },
    orgUtil() {
      return new OrgUtil(this, this.org);
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

